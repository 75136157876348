import { Outlet } from 'react-router';
import NavBarLandingPage from '../components/navBarLandingPage/NavBarLandingPage';
import Footer from '../components/footer/Footer';
import useIsMobile from '../hooks/useIsMobile';
import { useFetchOrganizationDetails } from '../hooks/useFetchOrganizationDetails';

// interface Props {
//   data: OrganizationDetails | null;
//   loading: boolean;
// }
const Layout = () => {
  const { isMobile } = useIsMobile();
  const { data, loading } = useFetchOrganizationDetails();
  return (
    <>
      {!isMobile && <NavBarLandingPage data={data} loading={loading} />}
      <main className='flex flex-col font-Montserrat leading-[19.49px] w-full'>
        <div className='bg-white'>
          <Outlet />
        </div>
      </main>
      {!isMobile && <Footer data={data} />}
    </>
  );
};

export default Layout;
