import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useSearchParams } from 'react-router-dom';
import Spinner from '../../components/spinner/Spinner';
import { useFetchOrganizationDetails } from '../../hooks/useFetchOrganizationDetails';
import envConfig from '../../utils/config';

interface EmailVerificationResponse {
  identifiers: Identifier[];
  generatedMaps: object[];
  raw: RawData;
}

interface Identifier {
  id: number;
  name: string;
}

interface RawData {
  fieldCount: number;
  affectedRows: number;
  insertId: number;
  info: string;
  serverStatus: number;
  warningStatus: number;
  changedRows: number;
}

const EmailVerification: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [emailVerificationData, setEmailVerificationData] =
    useState<EmailVerificationResponse | null>(null);
  const [msgStatus, setMsgStatus] = useState<{
    msg: string;
    statusCode: number;
  } | null>({ msg: '', statusCode: 0 });
  const { data, loading } = useFetchOrganizationDetails();
  const encryptedText = searchParams.get('h');

  useEffect(() => {
    const fetchEmailVerification = async () => {
      if (!encryptedText) {
        setMsgStatus({
          msg: 'Invalid or missing verification link.',
          statusCode: 0,
        });
        return;
      }

      try {
        if (emailVerificationData) {
          return;
        }
        const response = await axios.post(
          `${envConfig.REACT_APP_API_BASE_URL}/usr/u/e/v`,
          { encryptedText },
          {
            headers: {
              'x-api-key': envConfig.REACT_APP_API_X_API_KEY,
            },
          },
        );
        console.log('response: ', response);

        if (response.status === 200) {
          setEmailVerificationData(response.data);
          return;
        }
      } catch (err: any) {
        if (err.response) {
          if (err.response.status === 409) {
            setMsgStatus({
              msg: 'Email is already verified.',
              statusCode: err.response.status,
            });
          } else if (err.response.status === 404) {
            setMsgStatus({
              msg: 'Email not found.',
              statusCode: err.response.status,
            });
          } else if (err.response.status === 500) {
            setMsgStatus({
              msg: 'An error has occurred, please try again later.',
              statusCode: err.response.status,
            });
          } else {
            setMsgStatus({
              msg: 'There was an error verifying your email. Please try again later.',
              statusCode: err.response.status,
            });
          }
        } else if (err.request) {
          setMsgStatus({
            msg: 'No response from the server. Please check your internet connection.',
            statusCode: 0,
          });
        } else {
          setMsgStatus({
            msg: 'An unexpected error occurred. Please try again later.',
            statusCode: 0,
          });
        }
        console.error('Error: ', err);
      }
    };

    fetchEmailVerification();
  }, [encryptedText, emailVerificationData]);

  return (
    <div className='flex flex-col items-center justify-center p-4 mt-[4rem] md:mt-[10rem]'>
      {loading ? (
        <Spinner />
      ) : (
        <Link
          className=' w-[100%] md:w-auto mb-6 ml-[6rem] md:ml-[4rem]'
          to='/'
        >
          <img src={data?.logo} alt='' className='w-fit max-w-[18.75rem]' />
        </Link>
      )}

      {msgStatus?.msg && !emailVerificationData ? (
        <div
          className={`text-center text-[1.25rem] md:text-[1.5rem] md:mt-[2rem] leading-[2rem] ${
            msgStatus?.statusCode === 409 ? 'text-blue-700' : 'text-red-600'
          }`}
        >
          <p>{msgStatus.msg}</p>
        </div>
      ) : emailVerificationData ? (
        <div className='text-center'>
          <h1 className='text-[1.25rem] md:text-[2rem] font-semibold mb-8 md:mb-[4rem] mt-12 leading-8 md:leading-12 text-green-600'>
            Congratulations! Your email has been verified.
          </h1>
        </div>
      ) : (
        <div className='text-gray-600 text-center mt-[2rem] text-[1.25rem] md:text-[1.5rem] '>
          <p>Verifying your email, please wait...</p>
        </div>
      )}
    </div>
  );
};

export default EmailVerification;
